// load css file
import '../css/app.css';

import $ from 'jquery';
import 'bootstrap';


/*require('../plugins/greensock/TweenMax.min.js');
require('../plugins/greensock/TimelineMax.min.js');*/


import { TimelineLite, TweenMax, TimelineMax, ScrollToPlugin } from 'gsap/all';
import { ScrollMagic } from 'scrollmagic';

import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';

require('../plugins/OwlCarousel2-2.2.1/owl.carousel.js');
require('../plugins/scrollTo/jquery.scrollTo.min.js');
require('../plugins/easing/easing.js');
require('./custom.js');

// courses page
require('./elements_custom.js');
// contact us page
require('./contact_custom.js');
