$(document).ready(function () {
  const FORM_TIMEOUT = 60; // [secs]
  "use strict";
  let map;
  let formActive = false;


  initGoogleMap();
  initSubmitButton();
  initClickEventOnFormSubmitionStatus();

  function initGoogleMap() {
    if (typeof google == 'undefined') {
      // not my page...
      return;
    }

    let myLatlng = new google.maps.LatLng(44.7888862, 20.4865655);
    let mapOptions =
    {
      center: myLatlng,
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      draggable: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions:
      {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      styles: []
    }

    // Initialize a map with options
    map = new google.maps.Map(document.getElementById('map'), mapOptions);
    map.panBy(-45, 0);

    var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      title: 'Hello World!'
    });


    // Re-center map after window resize
    google.maps.event.addDomListener(window, 'resize', function () {
      setTimeout(function () {
        google.maps.event.trigger(map, "resize");
        map.setCenter(myLatlng);
      }, 1400);
    });
  }

  function initSubmitButton() {
    if (document.getElementById('contact_send_btn'))
      document.getElementById('contact_send_btn').onclick = submitForm;
  }

  function initClickEventOnFormSubmitionStatus() {
    let elements = document.getElementsByClassName('contact-form-status');
    for (let i = 0; i < elements.length; i++) {
      elements[i].onclick = function () {
        this.classList.remove('d-block');
        this.classList.add('d-none');
      }
    }
  }

  function submitForm(event) {

    // cancel submit event
    event.preventDefault();

    if (formActive) {
      return;
    }

    cleanUpForm(true); // force clean

    let form = document.getElementById('contact-form');
    // validate
    let isValid = true;
    for (let i = 0; i < form.children.length - 1; i++) {
      let child = form.children[i];

      isValid &= child.checkValidity();

      if (!child.checkValidity()) {
        child.classList.add('validation-error');
      } else {
        child.classList.remove('validation-error');
      }
    }

    if (!isValid) {
      cleanUpForm(false); // force clean
      return;
    }

    formActive = true;
    // clean up
    cleanUpForm(formActive);
    let isTimeout = false;
    $.ajax({
      method: 'post',
      url: form.action,
      type: 'json',
      data: {
        name: form.name.value,
        email: form.email.value,
        message: form.message.value
      },
      success: function (data, textStatus, jqXHR) {
        formActive = false;
        document.getElementById('form-submited').classList.add('d-block');
      },
      error: function (request, status, error) {
        formActive = false;
        if (request.responseJSON.message) {
          document.getElementById('form-not-submited').innerHTML = request.responseJSON.message;
        }

        if (request.responseJSON.timeout && request.responseJSON.timeout === true) {
          isTimeout = true;
          clearUpFormAfterTimeout();
        }

        document.getElementById('form-not-submited').classList.add('d-block');
      }
    }).always(function () {
      cleanUpForm(formActive);

      if (isTimeout) {
        // disable form and button when we'r stuck in timeout
        formActive = true;
        document.getElementById('contact_send_btn').classList.add('disabled');
      }
    });
  }

	/**
	 * Method which will clean up all form related elements, all according to form state (submitting argument)
	 *
	 * @param {boolean} submitting
	 */
  function cleanUpForm(submitting) {
    if (submitting) {
      document.getElementById('form-submited').classList.remove('d-block');
      document.getElementById('form-not-submited').classList.remove('d-block');

      document.getElementById('form-submited').classList.add('d-none');
      document.getElementById('form-not-submited').classList.add('d-none');

      document.getElementById('form-loader').classList.add('d-block');

      document.getElementById('contact_send_btn').classList.add('disabled');
    } else {
      document.getElementById('form-loader').classList.remove('d-block');
      document.getElementById('form-loader').classList.add('d-none');
      document.getElementById('contact_send_btn').classList.remove('disabled');
    }
  }

  /**
   * After 60secs form will be cleaned.
   */
  function clearUpFormAfterTimeout() {
    setTimeout(function () {
      formActive = false;
      document.getElementById('form-not-submited').innerHTML = '';
      cleanUpForm(formActive);
    }, (FORM_TIMEOUT * 1000));
  }

});
